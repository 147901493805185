<button
  class="circle-button circle-button_{{ size }} circle-button_{{ type }}"
  [class.circle-button_active]="isActive"
  [type]="type"
>
  @if (icon) {
    <svg-icon [src]="icon | svgPath" />
  }
  @if (text) {
    <span class="expocab-text-small">{{ text }}</span>
  }
</button>
