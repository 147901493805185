import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgPathPipe } from '@expocab/shared/libs';
import { SvgIconComponent } from 'angular-svg-icon';

type CircleButtonSize = 'big' | 'large' | 'medium' | 'small';
type CircleButtonType = 'number' | 'primary' | 'transparent' | 'white';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SvgPathPipe, SvgIconComponent],
  selector: 'expocab-circle-button',
  standalone: true,
  styleUrl: './circle-button.component.scss',
  templateUrl: './circle-button.component.html',
})
export class CircleButtonComponent {
  @Input() htmlType: 'button' | 'reset' | 'submit' = 'button';
  @Input() icon!: string;
  @Input() isActive!: boolean;
  @Input({ required: true }) size!: CircleButtonSize;
  @Input() text!: number | string;
  @Input({ required: true }) type!: CircleButtonType;
}
